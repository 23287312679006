<template>
	<div class="fixed">
		<img :src="require('@/assets/core/logo.png')" alt="Chiesi México" class="w-48 sm:w-56">
	</div>

	<div class="max-w-3xl space-y-4 pt-24 sm:pt-28 px-6 sm:mx-10 md:mx-20 pb-10">
		<h1 class="font-semibold text-xl">Términos y condiciones</h1>

		<p>Esta es una plataforma con contenidos exclusivos para profesionales de la salud. Queda estrictamente prohibido difundir o reproducir parcial o totalmente cualquier contenido fuera de esta plataforma.</p>	

		<p>Al entrar y usar esta aplicación web yo confirmo que:</p>

		<ul class="list-disc"> 
			<li>Soy un profesional de la salud y que cuento con la instrucción necesaria para acceder e interpretar la información de este portal.</li>
			<li>Que el propósito de acceso al presente portal de Chiesi México es con el fin de servir a la comunidad médica, para que el profesional de la salud a quien se le ha entregado el acceso, tenga una herramienta que contribuya a su actualización y práctica diaria, trasladándose así a un beneficio para con sus pacientes.</li>
			<li>Que el financiamiento de Chiesi México que permite mi participación y acceso a éste portal/App, no implica un conflicto de interés potencial o real o de cualquier otra índole que afecte o influya el desempeño de mis labores como profesional de la salud, ni en lo personal, ni 		derivado de mi relación con ninguna institución de salud tanto pública como privada.</li>
			<li>Que mis datos personales sean tratados por Chiesi México SA de CV así como por el proveedor contratado para la administración de éste sitio y que ante cualquier duda por el tratamiento de mis datos personales puedo consultar el aviso de privacidad en la página https://www.chiesi.mx/</li>
		</ul>
	</div>
</template>
